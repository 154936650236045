import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import {
    RouteWithLayout,
    PrivateRouteWithLayout,
    PreAuthRouteWithLayout
} from './components';

import {
    Main as MainLayout,
    Minimal as MinimalLayout
} from './layouts';

import {
    Login as LoginPage,
    Logout as LogoutPage,
    Register as RegisterPage,
    SyncSession as SyncSessionPage,

    InitPasswordReset as InitPasswordResetPage,
    PasswordReset as PasswordResetPage,
    ChangePassword as ChangePasswordPage,
    ConfirmEmail as ConfirmEmailPage,
    Home as HomePage,
    NotFound as NotFoundPage,
    AppInfo as AppInfoPage,

    // ReportView as ReportPage,

    ViewCompanyInfo as ViewCompanyInfoPage,
    UpdateCompanyInfo as UpdateCompanyInfoPage,
    ListApplications as ListApplicationPage,
    CreateApplication as CreateApplicationPage,
    ApplicationDetail as ApplicationDetailPage,
    EditAppProgramDetail as EditAppProgramDetailPage,
    EnQaMode as EnQaModePage,
    DsQaMode as DsQaModePage
} from './pages';
import CompanyInfoView from "./pages/CompanyInfo/View.page";
import SelectApplicationTypePage from "./pages/Application/SelectApplicationType.page";

const Routes = () => {
    return (
        <Switch>
            <Redirect
                exact
                from="/"
                to="/home"
            />
            <PreAuthRouteWithLayout
                component={LoginPage}
                exact
                layout={MinimalLayout}
                path="/login"
            />
            <PreAuthRouteWithLayout
                component={RegisterPage}
                exact
                layout={MinimalLayout}
                path="/register"
            />
            <PreAuthRouteWithLayout
                component={SyncSessionPage}
                exact
                layout={MinimalLayout}
                path="/sync_session"
            />

            <PreAuthRouteWithLayout
                component={InitPasswordResetPage}
                exact
                layout={MinimalLayout}
                path="/account/recover"
            />
            <PreAuthRouteWithLayout
                component={PasswordResetPage}
                exact
                layout={MinimalLayout}
                no_redirect={true}
                path="/auth/reset/password"
            />

            <PrivateRouteWithLayout
                component={ConfirmEmailPage}
                exact
                layout={MinimalLayout}
                path="/auth/confirm/email"
            />
            <PrivateRouteWithLayout
                component={HomePage}
                exact
                layout={MainLayout}
                path="/home"
            />
            {/*<PrivateRouteWithLayout*/}
            {/*    component={ReportPage}*/}
            {/*    exact*/}
            {/*    layout={MainLayout}*/}
            {/*    path="/report"*/}
            {/*/>*/}
            <PrivateRouteWithLayout
                component={ViewCompanyInfoPage}
                exact
                layout={MainLayout}
                path="/company/view"
            />
            <PrivateRouteWithLayout
                component={UpdateCompanyInfoPage}
                exact
                layout={MainLayout}
                path="/company/update"
            />
            <PrivateRouteWithLayout
                component={ListApplicationPage}
                exact
                layout={MainLayout}
                path="/application/list"
            />
            <PrivateRouteWithLayout
                component={ApplicationDetailPage}
                exact
                layout={MainLayout}
                path="/application/detail"
            />
            <PrivateRouteWithLayout
                component={EditAppProgramDetailPage}
                exact
                layout={MainLayout}
                path="/application/edit/program"
            />
            <PrivateRouteWithLayout
                component={CreateApplicationPage}
                exact
                layout={MainLayout}
                path="/application/create"
            />


            <PrivateRouteWithLayout
                component={ChangePasswordPage}
                exact
                layout={MainLayout}
                path="/password/change"
            />
            <PrivateRouteWithLayout
                component={LogoutPage}
                exact
                layout={MainLayout}
                path="/logout"
            />

            <PrivateRouteWithLayout
                component={SelectApplicationTypePage}
                exact
                layout={MainLayout}
                path="/application/select"
            />

            {/*########################*/}
            {/*### Miscellaneous -- ROUTES ###*/}
            {/*########################*/}

            <RouteWithLayout
                component={AppInfoPage}
                exact={false}
                layout={MinimalLayout}
                path="/app_info"
            />

            <RouteWithLayout
                component={EnQaModePage}
                exact={false}
                layout={MinimalLayout}
                path="/en_qa_mode"
            />

            <RouteWithLayout
                component={DsQaModePage}
                exact={false}
                layout={MinimalLayout}
                path="/ds_qa_mode"
            />

            <RouteWithLayout
                component={NotFoundPage}
                exact={false}
                layout={MinimalLayout}
                path="/"
            />

        </Switch>
    );
};

export default Routes;
