import {LOCAL_STORAGE_KEYS} from "../utils/constants";
import {invokeApi} from "../bl_libs/invokeApi";
import {use_e2ee} from "../utils/utils";

export const addProgramInfoService = (data) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/dqseal_client/program/add',
        method : 'POST',
        headers : {
            'Authorization' : `Bearer ${token}`
        }
    };
    requestObj['postData'] = {
        ...data
    };

    return invokeApi(requestObj, use_e2ee());
}

export const getAppDetailService = (application_id) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/dqseal_client/get/app_details',
        method : 'POST',
        headers : {
            'Authorization' : `Bearer ${token}`
        }
    };
    requestObj['postData'] = {
        application_id
    };

    return invokeApi(requestObj, use_e2ee());
}

export const updateAppProgramService = (application_id, form_data) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/dqseal_client/app/program/update',
        method : 'POST',
        headers : {
            'Authorization' : `Bearer ${token}`
        }
    };
    requestObj['postData'] = {
        application_id,
        ...form_data
    };

    return invokeApi(requestObj, use_e2ee());
}

export const getApplicationDataService = (application_id) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/dqseal_client/get/app_data',
        method : 'POST',
        headers : {
            'Authorization' : `Bearer ${token}`
        }
    };
    requestObj['postData'] = {
        application_id
    };

    return invokeApi(requestObj, use_e2ee());
}

export const updateApplicationDataService = (application_id, payment_proof_file_path) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/dqseal_client/update/app_data',
        method : 'POST',
        headers : {
            'Authorization' : `Bearer ${token}`
        }
    };
    requestObj['postData'] = {
        application_id,
        payment_proof_file_path
    };

    return invokeApi(requestObj, use_e2ee());
}

export const updateProgramContent = (application_id, module_db_id, file_path) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/dqseal_client/content/update',
        method : 'POST',
        headers : {
            'Authorization' : `Bearer ${token}`
        }
    };
    requestObj['postData'] = {
        application_id,
        module_db_id,
        file_path
    };

    return invokeApi(requestObj, use_e2ee());
}

export const listAllApplications = () => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/dqseal_client/app/list',
        method : 'GET',
        headers : {
            'Authorization' : `Bearer ${token}`
        }
    };

    return invokeApi(requestObj, use_e2ee());
}

export const getOverviewDataService = () => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/dqseal_client/overview/get',
        method : 'GET',
        headers : {
            'Authorization' : `Bearer ${token}`
        }
    };

    return invokeApi(requestObj, use_e2ee());
}

export const addModuleData = (application_id, data) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/dqseal_client/app/modules/add',
        method : 'POST',
        headers : {
            'Authorization' : `Bearer ${token}`
        }
    };
    requestObj['postData'] = {
        application_id,
        ...data
    };

    return invokeApi(requestObj, use_e2ee());
}

export const updateModuleData = (module_db_id, data) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/dqseal_client/app/modules/update',
        method : 'POST',
        headers : {
            'Authorization' : `Bearer ${token}`
        }
    };
    requestObj['postData'] = {
        module_db_id,
        ...data
    };

    return invokeApi(requestObj, use_e2ee());
}

export const deleteModuleData = (application_id, module_db_id) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/dqseal_client/app/modules/delete',
        method : 'POST',
        headers : {
            'Authorization' : `Bearer ${token}`
        }
    };
    requestObj['postData'] = {
        application_id,
        module_db_id,
    };

    return invokeApi(requestObj, use_e2ee());
}

export const listModuleData = (application_id) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/dqseal_client/app/modules/list',
        method : 'POST',
        headers : {
            'Authorization' : `Bearer ${token}`
        }
    };
    requestObj['postData'] = {
        application_id
    };

    return invokeApi(requestObj, use_e2ee());
}

export const submitModuleData = (application_id) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/dqseal_client/app/modules/submit',
        method : 'POST',
        headers : {
            'Authorization' : `Bearer ${token}`
        }
    };
    requestObj['postData'] = {
        application_id
    };

    return invokeApi(requestObj, use_e2ee());
}

export const deleteContent = (file_path) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/dqseal_client/content/remove',
        method : 'POST',
        headers : {
            'Authorization' : `Bearer ${token}`
        }
    };
    requestObj['postData'] = {
        file_path
    };

    return invokeApi(requestObj, use_e2ee());
}

export const getApplicationService = (application_id) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/dqseal_client/app/get',
        method : 'POST',
        headers : {
            'Authorization' : `Bearer ${token}`
        }
    };
    requestObj['postData'] = {
        application_id,
    };

    return invokeApi(requestObj, use_e2ee());
}

export const getApplicationAlignmentService = (application_id) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/dqseal_client/app/alignment/get',
        method : 'POST',
        headers : {
            'Authorization' : `Bearer ${token}`
        }
    };
    requestObj['postData'] = {
        application_id,
    };

    return invokeApi(requestObj, use_e2ee());
}

export const getAnalysisService = (application_id, analysis_type) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/dqseal_client/app/analysis/get',
        method : 'POST',
        headers : {
            'Authorization' : `Bearer ${token}`
        }
    };
    requestObj['postData'] = {
        application_id,
        analysis_type
    };

    return invokeApi(requestObj, use_e2ee());
}

export const uploadMedia = (media, progressCb) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/dqseal_client/content/upload',
        method : 'POST',
        headers : {
            'Authorization' : `Bearer ${token}`,
            'content-type': 'multipart/form-data'
        },
        onUploadProgress: progressCb
    };
    requestObj['postData'] = media;

    return invokeApi(requestObj);
}

export const getAppPaymentService = (application_id) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/dqseal_client/app/payment/get',
        method : 'POST',
        headers : {
            'Authorization' : `Bearer ${token}`
        }
    };
    requestObj['postData'] = {
        application_id,
    };

    return invokeApi(requestObj, use_e2ee());
}

export const getStripeConfigService = () => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path: '/dqseal_client/config/stripe',
        method : 'GET',
        headers : {
            'Authorization' : `Bearer ${token}`
        }
    }
    return invokeApi(requestObj, use_e2ee());
}

export const createStripeIntentService = (application_id) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/dqseal_client/app/payment/intent/create',
        method : 'POST',
        headers : {
            'Authorization' : `Bearer ${token}`
        }
    };
    requestObj['postData'] = {
        application_id,
    };

    return invokeApi(requestObj, use_e2ee());
}

export const updateStripeIntentService = (application_id, payment_intent) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/dqseal_client/app/payment/intent/update',
        method : 'POST',
        headers : {
            'Authorization' : `Bearer ${token}`
        }
    };
    requestObj['postData'] = {
        application_id,
        payment_intent
    };

    return invokeApi(requestObj, use_e2ee());
}

export const getSampleInstruments = (competency_list) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/dqseal_client/sample_instruments/get',
        method : 'POST',
        headers : {
            'Authorization' : `Bearer ${token}`,
        },
    };
    requestObj['postData'] = {
        competency_list
    };

    return invokeApi(requestObj, use_e2ee());
}

export const getLevel3Service = () => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/dqseal_client/meta_data/level3/list',
        method : 'GET',
        headers : {
            'Authorization' : `Bearer ${token}`
        }
    };

    return invokeApi(requestObj, use_e2ee());
}

export const getLevel6Service = () => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/dqseal_client/meta_data/level6/list',
        method : 'GET',
        headers : {
            'Authorization' : `Bearer ${token}`
        }
    };

    return invokeApi(requestObj, use_e2ee());
}

export const sendEmail = (app, type) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/dqseal_client/send_email/post',
        method : 'POST',
        headers : {
            'Authorization' : `Bearer ${token}`,
        },
    };
    requestObj['postData'] = {
        app,
        type
    };

    return invokeApi(requestObj, use_e2ee());
}