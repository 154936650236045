import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {LOCAL_STORAGE_KEYS} from "../../../utils/constants";
import {useAuth} from "../../../context/auth";

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

const SyncSession = () => {
    const query = useQuery();
    const data = query.get('data');
    const [decodedData, setDecodedData] = useState(null);

    const { setAuthToken, setAuthUser } = useAuth();

    useEffect(() => {
        // This effect runs once when the component mounts
        const syncFromParam = async () => {
            if (data) {
                try {
                    const decodedString = atob(data); // Decode Base64 string
                    const jsonData = JSON.parse(decodedString); // Parse JSON string
                    const user = jsonData.user;
                    const token = jsonData.token;
                    const refresh_token = jsonData.refresh_token;

                    localStorage.setItem(LOCAL_STORAGE_KEYS.auth_token, JSON.stringify(token));
                    setAuthToken(token);
                    localStorage.setItem(LOCAL_STORAGE_KEYS.auth_user, JSON.stringify(user));
                    localStorage.setItem(LOCAL_STORAGE_KEYS.user_email, JSON.stringify(user.email));
                    localStorage.setItem(LOCAL_STORAGE_KEYS.refresh_token, JSON.stringify(refresh_token));
                    setAuthUser(user);

                    setDecodedData({message: 'successfully synced'});
                } catch (error) {
                    console.error('Error decoding or parsing data:', error);
                }
            }
        };

        syncFromParam();
    }, [data]); // Dependency array includes data to re-run effect if data changes

    return (
        <div>
            <h1>Sync Session</h1>
            {decodedData ? (
                <div>Data: {JSON.stringify(decodedData)}</div>
            ) : (
                <div>Loading...</div>
            )}
        </div>
    );
};

export default SyncSession;